<template lang="html">
  <div class="">

    <div  v-if="this.forgotpassword_var" @click="forgotpassword()"   :style="{width: '100%',zIndex: 2,backgroundColor: 'black',opacity: 0.5,position:'fixed',marginLeft:'0%',marginTop:'0%', height: '100%' }">
    </div>

    <div  v-if="this.want_to_register" @click="want_to_register_func()"   :style="{width: '100%',zIndex: 2,backgroundColor: 'black',opacity: 0.5,position:'fixed',marginLeft:'0%',marginTop:'0%', height: '100%' }">
    </div>

    <div  v-if="this.want_to_register"  :style="{width: '70%',backgroundColor:'white' ,borderRadius: '67px', border: '5px solid #0A6338',color:'#0A6338', zIndex:'6',position:'fixed',marginLeft:'14%',fontSize:'25px', paddingTop:'5%',marginTop:'2%', height: '42%'}">
          <div @click="want_to_register_func()" :style="{color:'#0A6338',cursor:'pointer',marginTop:'-5%' ,marginLeft:'88%'}">
            X
          </div>
          <div :style="{width:'80%', marginLeft:'10%'}">
              Om du är en reparatör och vill ha ditt företag på vår sida så är du välkommen att maila oss på info@enjord.com så kommer vi gladeligen sätta upp ett möte för att hjälpa till med registreringen
          </div>
          <button type="button" @click="to_mail()" :style="{background: '#0A6338 0% 0%', borderStyle:'none',  marginTop:'1%',color:'white',fontSize:'19px',width: '154px',height: '46px' ,borderRadius: '12px'}" name="button">
            Kontakta oss
          </button>
    </div>

    <div class="enjord"  >
      <!-- <img  @click="explore_page()"  alt="enjordlogo"  :src= "`${publicPath}`+ 'enjordlogo.png'" :style="{height:'65px',textAlign:'left', cursor:'pointer',marginTop:'0.3%',marginLeft:'0%', width:'65px',borderRadius:'50%'}"> -->
    </div>

    <div  v-if="this.forgotpassword_var"  :style="{width: '70%',backgroundColor:'white' ,borderRadius: '67px', border: '5px solid #0A6338',color:'#0A6338', zIndex:'6',position:'fixed',marginLeft:'14%',fontSize:'22px', paddingTop:'5%',marginTop:'2%', height: '52%'}">
            <div @click="forgotpassword()" :style="{color:'#0A6338',cursor:'pointer',marginTop:'-5%' ,marginLeft:'88%'}">
              X
            </div>
             <div :style="{width:'94%', marginLeft:'2%'}">
               <div class="title">
                   Fyll i din email och vi kommer skicka ett temporärt lösenord
               </div>
               <div class="main_comp" >
                <label for="name_input">Din email <span :style="{color:'red'}">*</span></label>
                <input type="text" name="name_input" maxlength="500" placeholder="Fyll i din email" class="input_field"     v-model="email" >
               </div>
             </div>
            <button type="button" @click="request_password()" :style="{background: '#0A6338 0% 0%',marginLeft:'65%', borderStyle:'none' ,marginTop:'3%',color:'white',fontSize:'17px',height: '46px' ,borderRadius: '12px'}" name="button">
              Begär ett nytt lösenord
            </button>
    </div>
    <div   :style="{height:'100%',width:'100%', display:'flex', margin: '0 auto' }">
        <div class="welcome_div" >
          <h1 class="h1">
            Välkommen tillbaka till Enjord
          </h1>
          <div :style="{marginLeft:'0%', marginTop:'6%',height:'260px'}">
            <div class="input_box">
             <label class="input_box_label"  for="email">Användarnamn</label>
             <div class="input_box_input_class">
               <input :onfocusout='check_email_format()' type="text"  class="input_box_input" placeholder="Användarnamn" v-model="email">
             </div>
             <br>
             <label for="password" class="input_box_label">
               Lösenord
             </label><br>
             <div class="input_box_input_class" >
               <input type="password"  name="password"
               placeholder="********" value="" v-on:keyup.enter="onEnter"
               class="input_box_input" v-model="password">
             </div>
            </div>
            <div @click="forgotpassword()"
                  class="forgotpassword">
                Glömt lösenord
            </div>
          </div>
          <button @click="login()" :style="{borderRadius:'12px',borderStyle:'none' ,fontSize:'22px',height:'56px',width:'324px',marginTop:'10%',marginLeft:'4%',color:'white', backgroundColor:'#0A6338'}">
            Logga in
          </button>
        </div>
    </div>

    <div  class="account_class1" @click="register()">
      <div :style="{width:'330px',marginLeft:'3%',paddingTop:'1%' , margin: '0 auto'}">

        Vill du lägga upp ditt bolag på vår sajt? <br>
        <span :style="{fontWeight:'bold'}">
          Registrera dig här
        </span>

      </div>
    </div>

    <bottombar/>

  </div>
</template>

<!-- <div v-if="this.w <=450" :style="{marginLeft:'5%', marginTop:'4%'}">

  Välkommen till inloggninssidan på enjord.com<br>
  För tillfället är det bara möjligt att logga in på sin profil via datorn.<br><br>
  Kontakta oss på info@enjord.com om du har några frågor eller feedback.<br><br>

  Mvh<br>
  Teamet på Enjord

</div> -->


<script>
import axios from 'axios';
import {API_URL} from '../../config.js'
import emailjs from '@emailjs/browser'
import router from '../router/index'
 import bottombar from '../components/bottombar.vue';
export default {

  data:function(){
    return{
      publicPath: process.env.BASE_URL +'',
      emailexists:false,
      emailformatwrong:false,
      enableDisable:false,
      email:'',
      forgotpassword_var:false,
      password:'',
      want_to_register:false,
      w:0
    }
  },
  mounted(){
    this.w = window.screen.width

  },
  components:{
    bottombar
  },
  methods:{
    onEnter(){
        this.login()
    },
    want_to_register_func(){
      if(this.want_to_register){
        this.want_to_register = false
      }else{
        this.want_to_register = true
      }
    },
    to_mail_pass(){
      window.location.href="mailto:info@enjord.com?Subject=Jag behöver ett nytt lösenord:"
    },
    to_mail(){
        window.location.href="mailto:info@enjord.com?Subject=Jag vill gå med:"
    },
    request_password(){

      var self = this
      const rand = Math.random().toString(16).substr(2, 8);
      var temp_password = rand


      axios({
         method:'put',
         url: API_URL+ '/change_password',
         data:{"email":  this.email,'userid':0,  "password":temp_password, "page":'forgotpassword'}
       })
       .then(function(response){
          if(response.data=='found'){
            self.$alert("Ett email med ditt lösenord har skickats")

            var templateParams = {
              to_name: self.email,
              password:temp_password
            };
            emailjs.send('service_54orlpk', 'template_r661qfe',templateParams,'2HGpsoHrhTkdtQlEN')
              .then((result) => {
                  console.log('SUCCESS!', result.text);
              }, (error) => {
                  console.log('FAILED...', error.text);
              });
          }
          else{
            self.$alert("Ursäkta, denna användare existerar inte. Vänligen registrera och skapa ett konto.")
          }
       })
    },
    login(){
      var self= this;
       axios({
         method:'get',
         url: API_URL+ '/login'+'?email='+this.email+"&password="+this.password,
       })
       .then(function(response){
         if (response.data.message == 'Wrong Username' || response.data.message == 'Wrong Password' ){
           self.$alert(response.data.message)
         }
         else{
           self.$store.commit('set_current_user', response.data)
           router.push({ name:"profile" })
        }
       })
    },
    explore_page(){
     router.push({ name:"explore_page" })
    },
    forgotpassword(){
      if (this.forgotpassword_var){
        this.forgotpassword_var = false
      }
      else{
        this.forgotpassword_var = true
      }
    },
    register(){
      router.push({name:'register'})
    },
    check_email_format(){
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if(this.email != ''){
    if (re.test(this.email)){
      this.emailformatwrong = false
    }
    else{
      this.emailformatwrong = true
    }
  }
  else{
    this.emailformatwrong = false
  }
},

  }

}
</script>

<style lang="css" scoped>
input:focus {outline:none!important;}

@media (max-width:450px ){

  .welcome_div{
      width:90%;
      height:100%;
      margin-Left:6%;
        margin: 0 auto;
  }

  .enjord{
    display: none
  }

  .h1{
    font-Weight:bold;
    font-Size:23px;
    height:30px;
    color: #0A6338;
  }

  .forgotpassword{
    color:#0A6338;
    margin-Left:50%;
    height:28px;
    width:auto;
    font-Size:14px;
  }



}

@media (min-width:450px ){

  .welcome_div{
      width:36%;
      height:100%;
      margin-Left:6%;
        margin: 0 auto;
  }

  .enjord{
    width:90%;
    text-Align:left;
    margin-Left:2%;
    margin-Top:2%;
    height:36px;
  }

  .h1{
    font-Weight:bold;
    font-Size:33px;
    height:50px;
    color: #0A6338;
  }

  .forgotpassword{
    color:#0A6338;
    margin-Left:60%;
    height:28px;
    width:228px;
    font-Size:14px;
  }

}


.input_box{
  width: 95%;
  height: 234px;
  margin-left: 5%;
  margin-top: -2%;
    color: #0A6338;
    text-align: left;
}

.input_box_label{
  font-size: 15px;
  height: 15px;
  width: 60px;
  font-weight: bold;
  color: #0A6338;
  margin-left: 0%;
  margin-top: 2%;
  text-align: left;
}

.input_box_input_class{
  width: 95%;
  height: 59px;
  border-radius: 12px;
  color: #0A6338;
  margin-top: 2%;
  border: 4px solid #59419B6F ;
}


.input_box_input{
  width: 70%;
  height: 90%;
  border-radius: 12px;
  color: #0A6338;
  border-color: #0A6338;
  color: black;
  padding-left: 2%;
  border-style: none;
}
.account_class1{
  width: 99%;
  margin-left: 0%;
  height: 90px;
  /* margin-top: 4%; */
  color: #0A6338;
  cursor:pointer;

}

.title{
  width: 95%;
  height: 50px;
  margin-top:2%;
  font-size:25px;
  margin-left:0%;
  color:#0A6338;
  line-height: normal;
}
.input_field{
  border: 2px solid #59419B6F ;
  color:black;
  margin-top:1%;
  width:58%;
  margin-left: 2%;
   border-radius: 8px;

   height:29px;

}
.main_comp{
    margin-left: -5%;
    margin-top: 0%;
}

</style>
